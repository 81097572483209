
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from 'react-bootstrap/Button';
import { useParams, useNavigate } from 'react-router-dom';
import toast from '../../service/toaster';
import './addPrediction.css';
import matchService from '../../service/matchService'
import predictionService from '../../service/predictionService'


export default function MatchPrediction() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [match, setMatch] = useState(null);
  const [prediction, setPrediction] = useState({});
  const [selections, setSelections] = useState({});
  const [submitted, setSubmitted] = useState(false); // New state for tracking submission

  useEffect(() => {
    getItems();
  },[id]);

  useEffect(()=>{
    if (match) {
      // Check if userPrediction is present
      if (match.userPrediction) {
        setSelections(match.userPrediction);
        setSubmitted(true); // Set to true to disable submit if predictions are pre-filled
      } else {
        const initialSelections = {
          team1: { winner: true },
          team2: { winner: true }
        };

        match.sessions.forEach(session => {
          initialSelections.team1[session] = null;
          initialSelections.team2[session] = null;
        });

        setSelections(initialSelections);
      }
    }
  },[match])
  const getItems = async () => {
    try {
      let { data } = await predictionService.getPrediction(id);
      let data1 = await matchService.getMatch(id);

      console.log("prediction already", data1,data);
      let finalData = {...data1.data[0],userPrediction:data[0]}
      setMatch(finalData);
    }
    catch (err) {
      console.log(err);
      toast.error("Matche can't be seen!!!");
      navigate('/login');
    }
  }
  const getMatch = async () => {
    try {
      let { data } = await matchService.getMatch(id);
      console.log("match adding,match", data,match);
      let finalData = {...match,...data[0]}
      setMatch(finalData);
    }
    catch (err) {
      toast.error("Matches can't be seen!!!");
      navigate('/login');
    }
  }

  const handleSelection = (team, session, choice,selectedOdd) => {
    setSelections(prevSelections => ({
      ...prevSelections,
      [team]: {
        ...prevSelections[team],
        [session]: {choice,selectedOdd}
      }
    }));
  };

  const handleWinnerSelection = (team) => {
    const losingTeam = team === 'team1' ? 'team2' : 'team1';
    setSelections(prevSelections => ({
      ...prevSelections,
      [team]: { ...prevSelections[team], winner: true },
      [losingTeam]: { ...prevSelections[losingTeam], winner: false }
    }));
  };

  const handleSubmit = async () => {
    // Only submit if not already submitted
    if (!submitted) {
      await predictionService.doPrediction({...selections,match_id:id,"predictionType":"initial"});
      toast.success('Predictions submitted successfully!');
      setSubmitted(true); // Disable the button after submission
    }
  };

  if (!match?.predictions?.team1 || !selections?.team1) {
    console.log(selections,"selections");
    return <div>Loading...</div>;
  }
  else{
    console.log(match,"match");
  }

  return (
    <div className="matches-container">
      <div className="card-container">
        <Card className="match-card">
          <CardHeader
            title={match.title}
            subheader={match.published_at}
            className="card-header"
          />
          <div className="flag-container">
            <img src={match?.flagImages?.team1} alt="Team 1 Flag" className="team-flag" />
            <span className="vs-text">VS</span>
            <img src={match?.flagImages?.team2} alt="Team 2 Flag" className="team-flag" />
          </div>
          <CardContent>
            <Typography variant="body2" color="text.secondary" className="match-content">
              {match?.content?.length > 150 ? `${match?.content?.slice(0, 150)}...` : match?.content}
            </Typography>
            <Typography variant="body2" color="text.secondary" className="match-tags">
              {(match?.tags?.length) ? `#${match?.tags?.[0].split(",").join(" #")}` : ""}
            </Typography>
          </CardContent>

          {/* Session Options */}
          <div className="session-options-container">
            {/* Team 1 */}
            <div className="team-options">
              <h3 className="team-title">Team 1</h3>
              {match?.sessions?.map((session) => (
                <div className="session" key={session}>
                  <p>{session} Over Session Odds ({match?.predictions?.team1?.[session]?.[0]}-{match.predictions.team1[session][1]}):</p>
                  
                  <Button
                    variant={selections?.team1?.[session]?.choice === 'no' ? 'danger' : 'outline-danger'}
                    className={`session-button ${selections?.team1?.[session]?.choice === 'no' ? 'no' : selections?.team1?.[session]?.choice === 'yes' ? 'yes' : 'default'}`}
                    onClick={() => handleSelection('team1', session, 'no',match?.predictions?.team1?.[session]?.[0])}
                    disabled={submitted} // Disable if submitted
                  >
                    {match?.predictions?.team1?.[session]?.[0]}
                  </Button>
                  <Button
                    variant={selections?.team1?.[session]?.choice === 'yes' ? 'success' : 'outline-success'}
                    className={`session-button ${selections?.team1?.[session]?.choice === 'yes' ? 'yes' : selections?.team1?.[session]?.choice === 'no' ? 'no' : 'default'}`}
                    onClick={() => handleSelection('team1', session, 'yes',match?.predictions?.team1?.[session]?.[1])}
                    disabled={submitted} // Disable if submitted
                  >
                    {match?.predictions?.team1?.[session]?.[1]}
                  </Button>
                </div>
              ))}
              <div className={"winner-button-container"}>
                <Button
                  variant="success"
                  onClick={() => handleWinnerSelection('team1')}
                  className={selections?.team1?.winner ? "Winner" : "Loser"}
                  disabled={submitted} // Disable if submitted
                >
                  {selections?.team1?.winner ? "Winner" : "Loser"}
                </Button>
              </div>
            </div>

            {/* VS Separator */}
            <div className="vs-separator">
              <span>VS</span>
            </div>

            {/* Team 2 */}
            <div className="team-options">
              <h3 className="team-title">Team 2</h3>

              {match?.sessions?.map((session) => (
                <div className="session" key={session}>
                  <p>{session} Over Session Odds ({match?.predictions?.team2?.[session]?.[0]}-{match?.predictions?.team2?.[session]?.[1]}):</p>
                  
                  <Button
                    variant={selections?.team2?.[session]?.choice === 'no' ? 'danger' : 'outline-danger'}
                    className={`session-button ${selections?.team2?.[session]?.choice === 'no' ? 'no' : selections?.team2?.[session]?.choice === 'yes' ? 'yes' : 'default'}`}
                    onClick={() => handleSelection('team2', session, 'no',match?.predictions?.team1?.[session]?.[0])}
                    disabled={submitted} // Disable if submitted
                  >
                    {match?.predictions?.team2?.[session]?.[0]}
                  </Button>
                  <Button
                    variant={selections?.team2?.[session]?.choice === 'yes' ? 'success' : 'outline-success'}
                    className={`session-button ${selections?.team2?.[session]?.choice === 'yes' ? 'yes' : selections?.team2?.[session]?.choice === 'no' ? 'no' : 'default'}`}
                    onClick={() => handleSelection('team2', session, 'yes',match?.predictions?.team1?.[session]?.[1])}
                    disabled={submitted} // Disable if submitted
                  >
                    {match?.predictions?.team2?.[session]?.[1]}
                  </Button>
                </div>
              ))}
              <div className={"winner-button-container"}>
                <Button
                  variant="success"
                  onClick={() => handleWinnerSelection('team2')}
                  className={selections?.team2.winner ? "Winner" : "Loser"}
                  disabled={submitted} // Disable if submitted
                >
                  {selections?.team2.winner ? "Winner" : "Loser"}
                </Button>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          {!submitted &&
            <div className="submit-button-container">
            <Button
              variant="success"
              onClick={handleSubmit}
              disabled={submitted} // Disable if submitted
            >
              Submit
            </Button>
          </div>
          }
        </Card>
      </div>
    </div>
  );
}
