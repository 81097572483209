import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Login from '../components/auth/login'
import Registration from '../components/auth/registration'
import ForgotPassword from '../components/auth/ForgotPassword'
import ConfirmOtp from '../components/auth/ConfirmOtp'
import GetOtp from '../components/auth/GetOtp'
import PredictionView from "../components/prediction/PredictionView";
import Match from "../components/match/Match";
import AddMatch from "../components/match/AddMatch";
import AddTeam from "../components/team/addTeam";
import AddPlayer from "../components/player/addPlayer";
import Profile from "../components/profile";

import Blog from "../components/blog/Blog";
import Home from "../components/home/Home";
import AddBlog from "../components/blog/AddBlog";
import ViewBlog from "../components/blog/ViewBlog";
import PlayerList from "../components/player/playerList";
import TeamList from "../components/team/teamList";
import EditPlayer from "../components/player/editPlayer";
import AddPrediction from "../components/prediction/AddPrediction";

function router() {
    return (

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Home />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/match" element={<Match />} />
            <Route path="/blogs/add" element={<AddBlog />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blogs/view/:id" element={<ViewBlog />} />
            <Route path="/prediction/add/:id" element={<AddPrediction />} />
            <Route path="/prediction/view/:id" element={<PredictionView />} />
            
            
            {/* <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
            {/* <Route path="/getOTP" element={<GetOtp />} />
            <Route path="/confirmOTP" element={<ConfirmOtp />} /> */}
            <Route path="/prediction" element={<Match />} />
            <Route path="/add-match" element={<AddMatch />} />
            <Route path="/add-match/:id" element={<AddMatch />} />
            <Route path="/add-team" element={<AddTeam />} />
            <Route path="/add-player" element={<AddPlayer />} />

            <Route path="/players" element={<PlayerList />} />
            <Route path="/edit-player" element={<EditPlayer />} />
            <Route path="/teams" element={<TeamList />} />


        </Routes>
    )
}

export default router;

