import axios from 'axios'

const getMatches = async ()=>{
    try{
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.get(`${window.host}/match`, { headers });
        return data;
    }
    catch(err){
        if(process.env.NODE_ENV==='dev'){
            console.error(err.response.data.message);
        }
        return  []
    }
}

const getMatch = async (id)=>{
    try{
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
        };
        let { data } = await axios.get(`${window.host}/match/${id}`, { headers });
        return data;
    }
    catch(err){
        if(process.env.NODE_ENV==='dev'){
            console.error(err.response.data.message);
        }
        return  []
    }
}

const addMatch = async (matchData) => {
    try {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            throw new Error("Please Login!!!");
        }
        const headers = {
            authorization: `${accessToken}`,
            'Content-Type': 'application/json',
        };
        let { data } = await axios.post(`${window.host}/match`, matchData, { headers });
        return data;
    } catch (err) {
        if (process.env.NODE_ENV === 'dev') {
            console.error(err.response?.data?.message || err.message);
        }
        return null;
    }
};

export default {getMatches,getMatch,addMatch}