import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import './match.scss';
import toast from '../../service/toaster';
import matchService from '../../service/matchService';

export default function Matches() {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        try {
            let { data } = await matchService.getMatches();
            data = data.map(ele => ({ ...ele, isReadMore: true }));
            setItems(data);
            toast.success("All Matches Found");
        } catch (err) {
            toast.error("Matches can't be seen!!!");
            navigate('/login');
        }
    };

    const viewMatch = (matchId) => {
        try {
            toast.success("Match details found: " + matchId);
            navigate('/prediction/view/' + matchId);
        } catch (err) {
            toast.error("Cannot view match details!");
            navigate('/login');
        }
    };

    const addPrediction = (matchId) => {
        try {
            toast.success("Match details found: " + matchId);
            navigate("/add-match/" + matchId);
        } catch (err) {
            toast.error("Cannot view match details!");
            navigate('/login');
        }
    };

    const goToPrediction = (matchId) => {
        navigate(`/prediction/add/${matchId}`);
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        if (currentPage < Math.ceil(items.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="matches-container">
            <div style={{ display: 'flex' }}>
                <h1>Live Matches</h1>
            </div>
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
                {currentItems.map(elt => (
                    <Card className="card-cnt" sx={{ maxWidth: 345, margin: "10px", position: 'relative' }} key={elt._id}>
                        <CardHeader
                            title={
                                <>
                                    <div className="title-container">{elt.title}</div>
                                    {elt.matchStatus === "Live" ? (
                                        <div className="live-banner">LIVE</div>
                                    ) : (<div className="upcoming-banner">UPCOMING...</div>)}
                                </>
                            }
                            subheader={elt.published_at}
                        />
                        <div className="flag-container">
                            <img src={elt.flagImages.team1} alt="Team 1 Flag" className="flag-image" />
                            <span className="vs-text">VS</span>
                            <img src={elt.flagImages.team2} alt="Team 2 Flag" className="flag-image" />
                        </div>
                        {elt.venue&&<CardContent className='content-container' onClick={() => viewMatch(elt._id)}>
                            {<span style={{fontSize:"12px"}}>Venue:{elt.venue}</span>}
                        </CardContent>}
                        {elt.team_a_scores||elt.team_b_scores&&<CardContent className='content-container' style={{padding:"5px"}} onClick={() => viewMatch(elt._id)}>
                            <span style={{fontSize:"16px"}}>Score:{elt.team_a_scores||elt.team_b_scores}</span>
                        </CardContent>}
                        <div className='btn-cnt'>
                            <Button variant="contained" disabled={!elt.sessions.length} onClick={() => goToPrediction(elt._id)}>
                                Predict
                            </Button>
                            <Button variant="outlined" disabled={!elt.sessions.length} onClick={() => viewMatch(elt._id)}>
                                View Predictions
                            </Button>
                            {!elt.sessions.length && <Button variant="outlined" onClick={() => addPrediction(elt._id)}>
                                Add Predictions
                            </Button>}
                        </div>

                    </Card>
                ))}

            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls" style={{ textAlign: "center" }}>
                <Button variant="secondary" onClick={prevPage} disabled={currentPage === 1}>
                    Previous
                </Button>
                <span className="page-number"> Page {currentPage} of {Math.ceil(items.length / itemsPerPage)} </span>
                <Button variant="secondary" onClick={nextPage} disabled={currentPage === Math.ceil(items.length / itemsPerPage)}>
                    Next
                </Button>
            </div>

            {/* <div className="hover-button">
                <Button variant="primary" className="futuristic-button" onClick={() => navigate("/add-match")}>
                    <span className="button-icon">+</span>
                    <span className="button-text">Add Match</span>
                </Button>
            </div> */}
        </div>
    );
}
